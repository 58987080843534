<script>
export default {
  name: 'UiWallet',

  props: {
    wallet: {
      type: Object,
      required: true
    }
  },

  computed: {
    classes() {
      return {
        'ui-wallet': true,
        '-is-empty': this.wallet.featuredNfts.length === 0
      }
    }
  }
}
</script>

<template>
  <router-link
    :class="classes"
    :to="wallet.route"
  >
    <ui-panel class="ui-wallet__panel">
      <div class="ui-wallet__content">
        <template v-if="wallet.featuredNfts.length">
          <ui-illustration
            v-for="(nft, i) in wallet.featuredNfts"
            :alt="`${nft.name} nft illustration`"
            class="ui-wallet__illustration"
            :key="`wallet_${ wallet.id }_nft_$${ i }`"
            :src="nft.imageUrl"
            :type="nft.imageType"
          />

          <div
            v-if="wallet.count"
            class="ui-wallet__cta"
          >{{ $t('conn3ct-wallet.dashboard_wallet_more_nfts', { count: wallet.count }) }}</div>
        </template>

        <div
          v-else
          class="ui-wallet__empty"
        >
          <span>{{ $t('conn3ct-wallet.dashboard_wallet_empty') }}</span>
        </div>
      </div>
    </ui-panel>

    <span class="ui-wallet__title">{{ wallet.name }}</span>
  </router-link>
</template>
