<script>
import { mapState } from 'vuex'

import navigationData from '@/data/navigation'

import { Flicking } from "@egjs/vue-flicking"
import "@egjs/vue-flicking/dist/flicking.css"
import Config from '@/config'

import UiWallet from '../components/ui/wallet.vue'
import PoapLocation from '@/datalayer/poap/models/poap-location'
import { Nft } from '@/datalayer/wallet/models/wallet'

export default {
  name: 'ViewDashboard',

  components: {
    UiWallet,
    Flicking
  },

  data() {
    return {
      loading: false,
      loadingWallets: false,

      blockedMessage: null,

      nfts: [],
      searchSelected: false,
      searchValue: '',
      selectedBrand: null,
      sortField: 'date',
      showHiddenWallets: false,
      showWalletPass: false,

      gridColumns: 5,
      carouselFormat: 'carousel',

      hadUnconfirmedNfts: false,

      counts: {
        claims: 0,
        messages: 0,
        vouchers: 0,
        poaps: 0,
        challenges: 0
      }
    }
  },

  watch: {
    searchValue(value) {
      if(value) {
        window.sessionStorage.setItem('wallet-filters-search', value)
        return
      }

      window.sessionStorage.removeItem('wallet-filters-search')
    },

    sortField(value) {
      if(value) {
        window.sessionStorage.setItem('wallet-filters-field', value)
        return
      }

      window.sessionStorage.removeItem('wallet-filters-field')
    },

    selectedBrand(value) {
      if(value) {
        window.sessionStorage.setItem('wallet-filters-brand', value)
        return
      }

      window.sessionStorage.removeItem('wallet-filters-brand')
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap
    }),

    addToAppImage() {
      let image = this.isIos ? 'apple-wallet.svg' : 'wallet-passes.png'
      return this.$root.getSrc(`statics/images/${ image }`)
    },

    addToAppImageDesktop() {
      let image = 'apple-wallet.svg'
      return this.$root.getSrc(`statics/images/${ image }`)
    },

    banner() {
      return this.bootstrap?.data?.brand?.banner ?? null
    },

    brands() {
      return [
        {
          label: this.$t('conn3ct-wallet.brands_filter_placeholder'),
          value: null,
          active: !this.selectedBrand,
        },
        ...this.brandsList.map(b => {
          return {
            label: b.name,
            value: b.id,
            active: b.id === this.selectedBrand,
            logo: b.logo
          }
        })
      ]
    },

    brandsList() {
      return this.$brands.brands?.list ?? []
    },

    carouselComponent() {
      if(this.carouselFormat === 'carousel') {
        return Flicking
      }

      return 'div'
    },

    filteredNfts() {
      return this.nfts
        .filter(n => n.name.toUpperCase().includes(this.searchValue.toUpperCase()))
        .filter(n => !this.selectedBrand || n.saylAppBrand?.id === this.selectedBrand)
        .sort((nftA, nftB) => {
          let valueA, valueB = null
          let modifier = 1

          if(this.sortField === 'name') {
            valueA = nftA.name.toUpperCase()
            valueB = nftB.name.toUpperCase()
          }

          if(this.sortField === 'date') {
            modifier = -1
            valueA = nftA.saylNft?.modified
            valueB = nftB.saylNft?.modified
          }

          if(valueA > valueB) {
            return modifier * 1
          }

          if(valueA < valueB) {
            return modifier * -1
          }

          return 0
        })
    },

    isBrandedContext() {
      return !!sessionStorage.getItem('saylAppBrandId')
    },

    hasNfts() {
      let count = this.$wallet.unconfirmed?.length ?? 0

      this.wallets.forEach(w => {
        count += w.nftCount
      })

      return !!count
    },

    hiddenWallets() {
      return this.wallets.filter(w => {
        return !this.walletsQuickList.find(wa => (wa.id === w.id || (wa.id === 'sayl' && !w.id)))
      })
    },

    isSearchOpened() {
      return this.searchSelected || this.searchValue.length > 0
    },

    passLink() {
      return `${ Config.transports.cp.api }user/pass`
    },

    sortOptions() {
      return [
        {
          label: this.$t('conn3ct-wallet.nft_sort_by_name'),
          value: 'name',
          active: this.sortField === 'name',
        },

        {
          label: this.$t('conn3ct-wallet.nft_sort_by_date'),
          value: 'date',
          active: this.sortField === 'date',
        }
      ]
    },

    utilitiesItems() {
      return navigationData.filter(i => i.dashboard)
    },

    wallets() {
      return this.$dashboard.dashboard?.wallets ?? []
    },

    walletsQuickList() {
      return this.wallets?.slice(0, this.gridColumns).map(w => {
        let count = w.nftCount
        let featuredNfts = w.featuredNfts

        if(!w.id && this.$wallet.unconfirmed?.length) {
          count += this.$wallet.unconfirmed.length

          featuredNfts = [
            ...featuredNfts,
            ...this.$wallet.unconfirmed.map(n => n.nft)
          ]
        }

        return {
          count: count > 4 ? count - 3 : 0,
          featuredNfts: count > 4 ? featuredNfts.slice(0, 3) : featuredNfts.slice(0, 4),
          id: w.id ?? 'sayl',
          name: w.id ? w.name : this.$t(`conn3ct-wallet.${ w.name }`),
          route: { name: 'sayl-connect_wallet-wallet', params: { id: w.id ?? 'sayl' }},
        }
      })
    }
  },

  methods: {
    brandLogoUrl(logo) {
      if(typeof logo === 'string') {
        return logo
      }

      return logo.url?.all
    },

    closeSearch() {
      this.searchSelected = false
    },

    async countEntities() {
      try {
        const proms = [
          this.$wallet.getClaimables({}),
          this.$wallet.getToClaimNft({}),
          this.$vouchers.getWeb3Vouchers(),
          this.$vouchers.getWeb2Vouchers(),
          this.$challenges.getChallenges(),
        ]

        const res = await Promise.all(proms)

        this.counts.claims = res[0].length + res[1].length
        this.counts.vouchers = res[2].length + res[3].length
        this.counts.challenges = res[4].length
      } catch(e) {
        $console.error(e)
      }
    },

    getTags(entity) {
      const tags = []

      if(entity instanceof Nft) {
        tags.push(this.$t('conn3ct-wallet.dashboard_tag_nft'))

        if(entity.vouchers?.length) {
          tags.push(this.$t('conn3ct-wallet.vouchers_count', { vouchersCount: entity.vouchers.length }))
        }

        if(entity.web3Triggers?.length || entity.web3Vouchers?.length) {
          tags.push(this.$t('conn3ct-wallet.perks_count', { perksCount: entity.web3Triggers.length + entity.web3Vouchers.length }))
        }
      } else {
        tags.push(this.$t('conn3ct-wallet.dashboard_tag_poap'))
      }

      return tags
    },

    async init(resetCache = false) {
      this.loading = true
      this.hadUnconfirmedNfts = false

      try {
        await this.$brands.getBrands()
        const dashboard = await this.$dashboard.get(resetCache)
        await this.loadWallets(resetCache)

        this.counts.poaps = dashboard.poapsCount
        this.counts.messages = dashboard.totalMessages

        this.loadUnconfirmed()
        this.countEntities()
      } catch(e) {
        $console.error(e)

        if(e.response.status === 423) {
          this.blockedMessage = e.response.data?.message ?? 'locked_feature'
        }
      } finally {
        this.loading = false
      }
    },

    initFilters() {
      const search = window.sessionStorage.getItem('wallet-filters-search')
      const field = window.sessionStorage.getItem('wallet-filters-field')
      const brand = window.sessionStorage.getItem('wallet-filters-brand')

      if(search) {
        this.searchValue = search
      }

      if(field) {
        this.selectedBrand = field
      }

      if(brand) {
        this.selectedBrand = brand
      } else {
        this.selectedBrand = null
      }
    },

    async linkWallet() {
      try {
        const { token } = await this.$externalWallet.generateToken({ redirectUrl: '/dashboard' })
        this.$router.push({ name: 'sayl-connect_user-link-wallet', query: { token }})
      } catch(e) {
        $console.error(e)
      }
    },

    async loadUnconfirmed() {
      try {
        await this.$wallet.getUnconfirmed()

        this.$wallet.unconfirmed.forEach(n => {
          n.nft.saylAppBrand = this.$brands.brands.list.find(b => b.id === n.nft.saylAppBrand?.id)
        })

        setTimeout(() => {
          if(this.$wallet.unconfirmed?.length) {
            this.hadUnconfirmedNfts = true

            if(this.$route.name === 'sayl-connect_dashboard-dashboard') {
              this.loadUnconfirmed()
            }

            return
          }

          if(this.hadUnconfirmedNfts) {
            this.init(true)
          }
        }, 5000)
      } catch(e) {
        $console.error(e)
      }
    },

    async loadWallets(resetCache = false) {
      try {
        this.loadingWallets = true

        await this.$externalWallet.getWallets()

        const proms = [
          ...this.$externalWallet.wallets?.map(w => this.$wallet.getWallet(resetCache, w.id)),
          this.$wallet.getWallet(resetCache, 'sayl'),
          this.$poaps.getCollected()
        ]

        await Promise.all(proms)

        this.nfts = this.$wallet.wallets['sayl']?.nfts ?? []

        for(let wallet in this.$wallet.wallets) {
          if(wallet === 'sayl') continue

          this.nfts = [
            ...this.nfts,
            ...this.$wallet.wallets[wallet].nfts,
          ]
        }

        this.nfts = [
          ...this.nfts,
          ...this.$poaps.collectedPoaps.map(p => this.poapToNft(p))
        ]

        this.nfts.forEach(n => {
          n.saylAppBrand = this.$brands.brands.list.find(b => b.id === n.saylAppBrand?.id)
        })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loadingWallets = false
      }
    },

    openProfile() {
      this.$router.push({ name: 'sayl-connect_user-profile' }).catch(() => {})
    },

    openSearch() {
      this.searchSelected = true
    },

    poapToNft(poap) {
      return {
        id: poap.id,
        chain: null,
        imageUrl: poap.poapLocation.collectibleImage,
        name: poap.poapLocation.name,
        saylAppBrand: poap.poapLocation.saylAppBrand,
        saylNft: {
          modified: poap.created
        },
      }
    },

    resetFilters() {
      this.selectedBrand = null
      this.searchValue = ''
    },

    resize() {
      const width = window.innerWidth

      if(width <= 1024) {
        this.gridColumns = 5
        this.carouselFormat = 'carousel'
        return
      }

      this.carouselFormat = 'grid'
      this.gridColumns = 3

      if(width <= 1300) {
        this.gridColumns = 4
      }

      this.gridColumns = 5
    },

    toEntity(entity) {
      if(entity instanceof Nft) {
        return { name: 'sayl-connect_wallet-nft', params: { id: entity.id }}
      }

      return { name: 'sayl-connect_poaps-poap', params: { id: entity.id }}
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.resize)

    this.init()
    this.resize()

    this.initFilters()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>

<template>
  <div class="module-dashboard dashboard">
    <div
      v-if="banner"
      class="dashboard__banner"
    >
      <ui-illustration
        :alt="banner.alt.all"
        :src="banner.url.all"
        :type="banner.type"
      />
    </div>

    <layout-page :loading="loading && !nfts.length">
      <section class="dashboard__section">
        <header class="dashboard__section-header">
          <div class="left">
            <h2 class="dashboard__section-title">{{ $t('conn3ct-wallet.dashboard_wallets_list_title') }}</h2>
          </div>

          <div
            v-if="!blockedMessage"
            class="right"
          >
            <actions-button
              @click="linkWallet"
              class="dashboard__section-header-link"
            >
              <icon-link />
            </actions-button>

            <actions-button
              @click="showWalletPass = true"
              class="dashboard__section-header-link"
            >
              <icon-wallet />
            </actions-button>

            <actions-button
              @click="$router.push({ name: 'sayl-connect_wallet-transfers' })"
              class="dashboard__section-header-link"
            >
              <icon-transfer />
            </actions-button>
          </div>
        </header>

        <main class="dashboard__section-main">
          <ui-panel
            v-if="blockedMessage"
            class="dashboard__feature-locked"
          >
            <template v-slot:header>
              <h4 class="title">{{ $t(`conn3ct-wallet.${ blockedMessage }_title`) }}</h4>
            </template>

            <div class="body">
              <p>{{ $t(`conn3ct-wallet.${ blockedMessage }_description`) }}</p>

              <actions-button
                @click="openProfile"
                :appearance="$pepper.Appearance.PRIMARY"
                class="dashboard__feature-locked-cta"
              >{{ $t('conn3ct-wallet.open_profile') }}</actions-button>
            </div>
          </ui-panel>

          <component
            v-else
            :class="{
              'dashboard__wallet-carousel': carouselFormat === 'carousel',
              'dashboard__wallet-list': carouselFormat === 'grid',
            }"
            :is="carouselComponent"
            :options="{
              align: 'prev'
            }"
          >
            <ui-wallet
              v-for="wallet in walletsQuickList"
              class="dashboard__wallet-carousel-item"
              :key="wallet.id"
              :wallet="wallet"
            />
          </component>

          <actions-button
            v-if="hiddenWallets.length"
            @click="showHiddenWallets = true"
            class="dashboard__more-wallets"
          >{{ $t('conn3ct-wallet.dashboard_more_wallets', { count: hiddenWallets.length }) }}</actions-button>
        </main>
      </section>

      <section
        v-if="!hasNfts && !filteredNfts.length"
        class="dashboard__section utilities"
      >
        <header class="dashboard__section-header">
          <h2 class="dashboard__section-title">{{ $t('conn3ct-wallet.dasboard_utilities') }}</h2>
        </header>

        <main class="dashboard__section-main utilities__list">
          <router-link
            v-for="item in utilitiesItems"
            class="utilities__item"
            :key="item.slug"
            :to="{ name: item.route }"
          >
            <ui-panel>
              <div class="body utilities__content">
                <component
                  class="utilities__icon"
                  :is="item.icon"
                />

                <div class="utilities__title">{{ $t(item.label) }} {{ counts[item.slug] ? `(${ counts[item.slug] })` : '' }}</div>
              </div>
            </ui-panel>
          </router-link>
        </main>
      </section>

      <section
        v-else
        class="dashboard__section nfts"
      >
        <header class="dashboard__section-header">
          <h2 class="dashboard__section-title">{{ $t('conn3ct-wallet.dashboard_collectibles') }}</h2>
        </header>

        <main class="dashboard__section-main">
          <template>
            <div
              class="nfts__filters"
              :class="{ '-search-opened': isSearchOpened }"
            >
              <forms-input
                @focus="openSearch"
                @blur="closeSearch"
                v-model="searchValue"
                :appearance="$pepper.Appearance.SUBTLE"
                class="filters__name"
                :placeholder="$t('conn3ct-wallet.wallet_search_placeholder')"
              >
                <template v-slot:prefix>
                  <icon-search @click="openSearch" />
                </template>
              </forms-input>

              <forms-select
                v-model="sortField"
                class="filters__order-type"
                :options="sortOptions"
              >
                <template v-slot:prefix>
                  <div class="filters__select-prefix sort-type">
                    <icon-arrow orientation="bottom" />
                  </div>
                </template>
              </forms-select>

              <forms-select
                v-if="!isBrandedContext"
                v-model="selectedBrand"
                class="filters__brands"
                :options="brands"
              >
                <template v-slot:prefix>
                  <icon-handbag class="filters__select-prefix" />
                </template>
              </forms-select>
            </div>

            <layout-entity-list
              @emptyAction="resetFilters"
              class="dashboard__nfts"
              :empty="!filteredNfts.length && !$wallet.unconfirmed.length"
              :empty-action-label="$t('conn3ct-wallet.reset_filters')"
              :empty-description="$t('conn3ct-wallet.no_nfts_result_description')"
              :empty-title="$t('conn3ct-wallet.no_nfts_result_title')"
              :loading="loadingWallets"
            >
              <ui-card
                v-for="nft in $wallet.unconfirmed"
                class="nfts__transfer"
                :key="nft.id"
                :title="nft.nft.name"
              >
                <template v-slot:illustration>
                  <ui-illustration
                    :alt="nft.nft.name"
                    fit="contain"
                    :src="nft.nft.imageUrl"
                    :type="nft.nft.imageType"
                  />
                </template>

                <template
                  v-if="$basil.get(nft, 'nft.saylAppBrand.name')"
                  v-slot:subtitle
                >
                  <div class="nfts__nft-brand">
                    <ui-illustration
                      v-if="$basil.get(nft, 'nft.saylAppBrand.logo')"
                      :alt="$basil.get(nft, 'nft.saylAppBrand.name')"
                      class="nfts__nft-brand-logo"
                      :src="brandLogoUrl(nft.nft.saylAppBrand.logo)"
                    />

                    <span class="ui-card__subtitle">{{ $basil.get(nft, 'nft.saylAppBrand.name') }}</span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="row">
                    <button class="nfts__nft-cta border-animation">
                      {{ $t('conn3ct-wallet.in_transfer') }}
                    </button>
                  </div>
                </template>
              </ui-card>

              <ui-card
                v-for="(nft, i) in filteredNfts"
                class="nfts__nft"
                :key="`${ nft.id }_${ i }`"
                :title="nft.name"
                :tags="getTags(nft)"
                :to="toEntity(nft)"
              >
                <template v-slot:illustration>
                  <ui-illustration
                    :alt="nft.name"
                    fit="contain"
                    :src="nft.imageUrl"
                    :type="nft.imageType"
                  />
                </template>

                <template
                  v-if="$basil.get(nft, 'saylAppBrand.name')"
                  v-slot:subtitle
                >
                  <div class="nfts__nft-brand">
                    <ui-illustration
                      v-if="$basil.get(nft, 'saylAppBrand.logo')"
                      :alt="$basil.get(nft, 'saylAppBrand.name')"
                      class="nfts__nft-brand-logo"
                      :src="brandLogoUrl(nft.saylAppBrand.logo)"
                    />

                    <span class="ui-card__subtitle">{{ $basil.get(nft, 'saylAppBrand.name') }}</span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="row">
                    <div class="nfts__nft-chain">
                      <img
                        v-if="nft.chain"
                        :alt="`${nft.chain.value} logo`"
                        :src="$root.getSrc(`statics/images/${nft.chain.value}.png`)"
                      />
                    </div>

                    <button class="nfts__nft-cta">
                      {{ $t('conn3ct-wallet.view') }}
                    </button>
                  </div>
                </template>
              </ui-card>
            </layout-entity-list>
          </template>
        </main>
      </section>

      <popins-modal
        class="wallets-modal"
        :closable="false"
        position="middle-center"
        :size="$pepper.Size.S"
        :visible="showHiddenWallets"
        scrollLocked
      >
        <template v-slot:header>
          <div class="wallets-modal__header">
            <h2 class="wallets-modal__title">{{ $t('conn3ct-wallet.dashboard_wallets_list_title') }}</h2>

            <button
              @click="showHiddenWallets = false"
              class="wallets-modal__close"
            >
              <icon-cross />
            </button>
          </div>
        </template>

        <div class=wallets-modal__list>
          <router-link
            v-for="wallet in hiddenWallets"
            class="wallets-modal__item"
            :key="wallet.id"
            :to="{ name: 'sayl-connect_wallet-wallet', params: { id: wallet.id }}"
          >
            <span class="label">{{ wallet.name }}</span>

            <div class="icon">
              <icon-arrow orientation="right" />
            </div>
          </router-link>
        </div>
      </popins-modal>

      <popins-modal
        class="wallet-pass-modal"
        :closable="false"
        position="middle-center"
        :size="$pepper.Size.S"
        :visible="showWalletPass"
        scrollLocked
      >
        <template v-slot:header>
          <div class="wallet-pass-modal__header">
            <h2 class="wallet-pass-modal__title">{{ $t(`conn3ct-wallet.download_pass_title_${ os }`) }}</h2>

            <button
              @click="showWalletPass = false"
              class="wallet-pass-modal__close"
            >
              <icon-cross />
            </button>
          </div>
        </template>

        <section class="wallet-pass-modal__links">
          <actions-button
            :href="passLink"
            target="_blank"
            :appearance="$pepper.Appearance.LINK"
            :class="{
              'wallet-pass-modal__download-pass-action': true
            }"
          >
            <img
              :src="addToAppImage"
              class="wallet-pass-modal__download-pass-illustration"
            />
          </actions-button>

          <actions-button
            v-if="isDesktop"
            :href="passLink"
            target="_blank"
            :appearance="$pepper.Appearance.LINK"
            :class="{
              'wallet-pass-modal__download-pass-action': true
            }"
          >
            <img
              :src="addToAppImageDesktop"
              class="wallet-pass-modal__download-pass-illustration"
            />
          </actions-button>
        </section>

        <details class="wallet-pass-modal__instructions">
          <summary class="wallet-pass-modal__section-title">
            {{ $t('conn3ct-wallet.download_pass_instruction') }}

            <icon-carret
              class="icon"
              orientation="bottom"
            />
          </summary>

          <p
            v-html="$t(`conn3ct-wallet.download_pass_description_${ os }`)"
            class="wallet-pass-modal__download-pass-description"
          ></p>
        </details>
      </popins-modal>
    </layout-page>
  </div>
</template>
